import Loader from "components/atomics/loader";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

const IndexPage = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace('/me');
  }, []);

  return (
    <>
      <Loader loading/>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (
  ctx
) => {
  return {
    props: {}
  };
}

export default IndexPage;
